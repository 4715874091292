/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199px) {

}


/* Medium devices (tablets, less than 992px) */
@media (max-width: 991px) {

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767px) {
  .ryan-hero h1 {
    font-size: 6em;
    padding-top: 20%;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575px) {
 .ryan-hero h1 {
   font-size: 4em;
 }

 h2 {
   font-size: 2em;
 }

 .listening h3 {
   font-size: 1.2em;
 }

 .month h3{
   text-transform: uppercase;
   color: white;
   text-align: left;
   padding-left: 10px;
   padding-right: 10px;
   width: 100%;
   font-size: 1.6em;
 }

 .line {
   position: absolute;
   width: 8px;
   height: 40vh;
   background-color: #ff006e;
   right: 4%;
   top: 60%;
   border-bottom-right-radius: 3px;
   border-bottom-left-radius: 3px;
 }

 .listening .col-md-4 {
   margin-bottom: 30px;
 }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 300px) {
 .ryan-hero h1 {
   font-size: 2em;
 }
}
