footer{
	text-align: center;
	padding: 0% 0% 1% 0%;
}

footer ul {
	padding: 0;
}

footer p {
	font-size: 1em;
}

.legals {
	color: #526173;
	font-size: 0.8em;
}

.legals a {
	color: #526173;
	border-color: #526173;
}

.legals a:hover {
	color: #000;
	border-color: #000;
}

/*========== SOCIAL ==========*/

.social a {
	width: 100%;
}

.social ul{
	text-align: center;
	margin-bottom: 0;
}

.social li{
	display: inline-block;
}

.social p {
	margin-bottom: 0;
}

.social a {
	border: none;
}

.social a:hover {
	border: none;
}

@font-face {
    font-family: "socicon";
    src: url('../../assets/fonts/socicon.eot');
    src: url('../../assets/fonts/socicon.eot?#iefix') format('eot'), url('../../assets/fonts/socicon.ttf') format('truetype'), url('../../assets/fonts/socicon.woff') format('woff'), url('/fonts/socicon.svg#socicon') format('svg')
}

.icon{
	color: #000;
	font-family: "socicon";
	transition: background-color, color ease-in-out 0.25s;
	display: inline-block;
	padding: 8px 16px;
	font-size: 1.8em;
}

.socicon-github:before {
	content: "\e030";
}

.socicon-twitter:before {
  content: "\e040";
}

.socicon-linkedin:before {
  content: "\e049";
}

.socicon-instagram:before {
  content: "\e057";
}

a:hover .socicon-github:before, a:hover .socicon-twitter:before,
a:hover .socicon-linkedin:before, a:hover .socicon-instagram:before {
	color: #526173;
}