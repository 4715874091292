.blog, .projects {
  margin: 0 auto;
  margin-top: 30px;
}

.blog p {
  margin-bottom: 0;
}

.blog ul {
  margin-bottom: 0;
}

.blog ul li {
  margin-bottom: 10px;
}

.blog .posts {
  margin-left: 10px;
}

.blog .post-tag {
  animation: identifier 20s linear infinite;
}

.blog .blog-post a {
  position: relative;
}

.blog .blog-post div:first-child {
  border: 2px solid #526173;
  padding: 20px 25px;
  margin-bottom: 40px;
  border-radius: 8px;
}

.blog .blog-post:hover div{
  border-color: #000;
}

.blog .blog-post div:first-child:hover > h3 {
  color: #000;
}

.blog .blog-post div:first-child:hover > p {
  color: #000;
}

.blog .blog-post:hover .post-tag {
  border-color: #000;
}

.blog .blog-post .post-tag {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 2px solid #526173;
  background-color: #f6e8e5;
  top: -24px; right: -8px;
  height: 50px; width: 50px;
}