@keyframes rotate360 {
    to {
        transform: rotate(360deg); 
    }
}

.ryeen16-root img { 
    max-width: 99%;
    max-height: 77%;
    will-change: transform;
    animation: 2.5s rotate360 infinite linear;
}

.ryeen16-root h1 {
    font-family: "Comic Sans MS", "Comic Sans", cursive;
    color: pink;
    position: absolute;
}