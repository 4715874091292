.ryeen18-root h2 {
  padding: 0;
  margin: 0;
  width: fit-content;
  padding-bottom: 0.2rem;
  border-bottom: 0.6rem solid #526173;
}

.ryeen18-root h3 {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
}

.ryeen18-root tr { 
  vertical-align: top;
} 

.ryeen18-table {
  height: 100%;
  margin: 0;
  padding: 0;
}
 
.ryeen18-table .item {
  min-width: 95vw;
  max-width: 95vw;
  padding: 2rem;
}

.ryeen18-table .item.hero {
  min-width: 95vw;
  max-width: 95vw;
}

.ryeen18-root .hero {
  height: 100%;
}

.ryeen18-table .item .row {
  margin-top: 8vh;
}

.ryeen18-root .question-mark {
  font-size: 8rem;
  text-align: center;
  margin-top: 25vh;
}