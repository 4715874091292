.experience {
  padding-top: 20px;
}

.role {
  margin-bottom: 10px;
}

.exp{
	margin: 0 auto;
	padding: 0% 10% 0% 10%;
}

.plynk {

}

.idly {

}

.opsh {

}

.ducss {

}

.minicorp {

}

.csc {

}
