.ryeen18-root {
  height: 100vh;
}

.ryeen18-root a {
    padding-left: 2px;
    padding-right: 2px;
    border: none;
}

.ryeen18-root a:hover {
    text-decoration: underline wavy;
    padding-bottom: 3px;
    border: none;
}