.ryeen-day a {
  text-decoration: none;
  border: none;
}

.ryeen-day a:hover {
  text-decoration: none;
  border: none;
}

.card {
    display: inline-block;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.15);
    margin: 20px;
    position: relative;
    margin-bottom: 50px;
    transition: all .2s ease-in-out;
    padding: 40px 60px;
  }
  
.card:hover {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  margin-bottom: 54px;
}

.ryeen-day-17-style {
      color: #000;
      text-decoration: underline wavy;
      text-transform: uppercase;
}