.project-thumbnail {
  text-align: center;
  margin-bottom: 30px;
}

.project-thumbnail img {
  max-width: 100%;
  text-align: center;
}

.project-thumbnail h5 {
  margin-top: 10px;
  font-weight: 600;
}

.project-thumbnail:hover {
  color: #000;
  opacity: .6;
  text-decoration: underline;
}

.project-thumbnail:hover a h5 {
  color: #000;
}