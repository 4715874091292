.ryeen17-root {
    background-color: #ff006e;
    text-transform: uppercase;
}

.ryeen17-root h2 {
    color: #fff !important;
}

.ryeen17-root h4 {
    color: #fff !important;
}

.ryeen17-root h5 {
    color: #fff !important;
}

.ryeen17-root a {
    padding-left: 2px;
    padding-right: 2px;
    border: none;
}

.ryeen17-root a:hover {
    text-decoration: underline wavy;
    padding-bottom: 3px;
    border: none;
}