.about {
    padding: 4% 0;
  }
  
.recents {
  width: 80%;
  margin: 0 auto;
}

.hero .bamboo-link {
  color: #00c6e0;
}

.hero .bamboo-link:hover {
  color: #fff;
  background-color: #00c6e0;
}

.hello {
  margin: 5% 0;
}

.bubble {
  margin: 0 auto;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  width: max-content;
  max-width: 100%;
  border-radius: 8px;
  background-color: #f6f9fc;
  position: relative;
  text-align: left;
  padding: 20px 20px;
}

.bubble h3 {
  padding-right: 30px;
}

.bubble .me-pic {
  position: absolute;
  border-radius: 100%;
  top: -30px; right: -30px;
  height: 80px; width: 80px;
  border: 5px solid #f6f9fc;
}
  