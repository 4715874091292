body {
  font-family: Open Sans,sans-serif;
}

.section {
  margin: 0 10%;
  width: 80%;
}

p {
  color: #526173;
}

h1, h2, h3, h4, h5 {
  color: #526173;
}

.page-title {
  padding-bottom: 7px;
  display: inline-block; 
  margin-bottom: 20px;
  font-weight: 700;
  color: #000;
}

a {
  color: #526173;
  font-weight: 600;
  border-bottom: 2px dotted #526173;
}

a:hover {
  color: #000;
  border-bottom-color: #000;
  border-bottom-style: solid;
  text-decoration: none;
}