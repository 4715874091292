
body > div > div > .col-md-12 {
  padding-left: 2%;
  padding-right: 2%;
}

iframe {
  max-width: 100%;
}

.box {
  padding: 5% 0 5% 0;
}

.line {
  position: absolute;
  width: 8px;
  height: 35vh;
  background-color: #ff006e;
  right: 4%;
  top: 70%;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.line::after{
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 24px 12px 0;
  transform: rotate(270deg);
  border-color: transparent #ff006e transparent transparent;
  display: block;
  position: relative;
  left: -8px;
  top: 98%;
}

.ryan-hero {
  background-color: white;
  margin-top: 1.5%;
  height: 98.5vh;
  position: relative;
}

.ryan-hero h1 {
  position: absolute;
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
  color: #292f33;
  font-size: 9em;
  top: 2.5%;
  text-transform: uppercase;
}

h2 {
  font-size: 64px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 5%;
  font-weight: 600;
}

/*
  YIR
*/

.year-in-review {
  background-color: #bd00ff;
  position: relative;
}

.year-in-review img{
  max-width: 100%;
}

.month {
  height: 360px;
  background-color: #bd00ff;
  position: relative;
  margin-bottom: 30px;
}

.text-box {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
}

.month h3{
  text-transform: uppercase;
  color: white;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.month p {
  text-transform: uppercase;
  color: #bd00ff;
  background-color: white;
  width: auto;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
}

/*
  Listening
*/

.listening {
  background-color: #00ffa2;
}

.listening h3 {
  background-color: white;
  color: #00ffa2;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

/*
  PLACES
*/

.places {
  background-color: #0078ff;
}

.places ul {
  margin-left: 10%;
  margin-right: 10%;
  list-style: lower-greek;
  color: white;
}

.places ul li ol {
  list-style: lower-roman;
}

.places ul li {
  padding-bottom: 10px;
}

.places span {
  padding: 2px 4px;
  color: #292f33;
  background-color: white;
  text-transform: lowercase;
}

.places a {
  color: white;
  text-decoration: underline;
}

.places a:hover {
  color: #0078ff;
  background-color: white;
}

/*
  OUTRO
*/

.outro h3 {
  padding-left: 5%;
  padding-right: 5%;
  line-height: 42px;
  text-align: center;
  color: white;
}


.outro a {
  color: #ff006e;
  background-color: white;
  text-decoration: underline;
}

.outro a:hover {
  color: white;
  background-color: #ff006e;
}

/*
  MADE IN DUBLIN
*/

.made h5 {
  background-color: #292f33;
  color: white;
  width: initial;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 0;
}

.made a {
  background-color: white;
  color: #292f33;
  text-transform: lowercase;
}

.made a:hover {
  background-color: white;
  color: #000dff;
}

/*
  IMAGES
*/

.bg-cover:before {
  content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: .8;
}

.bg-cover:before:hover {
  opacity: 0;
}

sup {
  text-transform: lowercase;
}

.bg-jan:before {
	background-image: linear-gradient(to bottom right, #002f4b, #dc4225);
}

.bg-jan {
  background-image: url("~assets/images/ryeen-day/2017/jan.jpg");
  background-size: cover;
  background-position-x: center;
}

.bg-feb:before{
  background-image: linear-gradient(to bottom right, #002f4b, grey);
}

.bg-feb {
  background-image: url("~assets/images/ryeen-day/2017/feb.jpg");
  background-size: cover;
}

.bg-march:before{
  background-image: linear-gradient(to bottom right, #002f4b, #ff4000);
}

.bg-march {
  background-image: url("~assets/images/ryeen-day/2017/march.jpg");
  background-size: cover;
  background-position-x: center;
}

.bg-april:before{
  background-image: linear-gradient(to bottom right, #002f4b, #000dff);
}

.bg-april {
  background-image: url("~assets/images/ryeen-day/2017/april.jpg");
  background-size: cover;
  background-position-x: center;
}

.bg-may:before{
  background-image: linear-gradient(to bottom right, #002f4b, #00ff84);
}

.bg-may {
  background-image: url("~assets/images/ryeen-day/2017/may.jpg");
  background-size: cover;
  background-position-x: center;
}

.bg-june:before{
  background-image: linear-gradient(to bottom right, #002f4b, #c800ff);
}

.bg-june {
  background-image: url("~assets/images/ryeen-day/2017/june.jpg");
  background-size: cover;
  background-position-x: center;
}

.bg-july:before{
  background-image: linear-gradient(to bottom right, #002f4b, #ff0400);
}

.bg-july {
  background-image: url("~assets/images/ryeen-day/2017/july.jpg");
  background-size: cover;
  background-position-x: center;
}

.bg-aug:before{
  background-image: linear-gradient(to bottom right, #002f4b, darkgrey);
}

.bg-aug {
  background-image: url("~assets/images/ryeen-day/2017/aug.jpeg");
  background-size: cover;
  background-position-x: center;
}

.bg-sept:before{
  background-image: linear-gradient(to bottom right, #002f4b, #ff0400);
}

.bg-sept {
  background-image: url("~assets/images/ryeen-day/2017/sept.jpg");
  background-size: cover;
  background-position-x: center;
}

.bg-oct:before{
  background-image: linear-gradient(to bottom right, #002f4b, #000dff);
}

.bg-oct {
  background-image: url("~assets/images/ryeen-day/2017/oct.jpeg");
  background-size: cover;
  background-position-x: center;
}

.bg-nov:before{
  background-image: linear-gradient(to bottom right, #002f4b, #ff00d0);
}

.bg-nov {
  background-image: url("~assets/images/ryeen-day/2017/nov.jpg");
  background-size: cover;
  background-position-x: center;
}

.bg-dec:before{
  background-image: linear-gradient(to bottom right, #002f4b, #d4ff00);
}

.bg-dec {
  background-image: url("~assets/images/ryeen-day/2017/dec.jpg");
  background-size: cover;
  background-position-x: center;
}
