.navbar {
  padding: 1.8rem 0;
  width: 100%;
  min-height: 50px;
}

.navbar a {
  color: #526173;
  font-weight: 600;
}

.navbar a:hover {
  color: #000;
  text-decoration: none;
}

.logo-title {
  display: inline;
  vertical-align: bottom;
  font-size: 1.45rem;
  line-height: 1.8rem;
  color: rgb(184, 122, 122);
  font-weight: 700;
  border-bottom: 0px;
}

.router-link {
  color: #000;
  cursor: pointer;
  margin: 0 15px;
}

.router-link:hover {
  text-decoration: underline;
}

.navbar .router-link-active {
  color: #000;
  border-bottom-style: solid;
  border-bottom-color: #000;
}

.navbar .logo-title-active {
  color: #000;
  border-bottom-style: solid;
  border-bottom-color: #000;
}